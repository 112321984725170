// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {StatisticLabelBadge} from '@modules/Core/components/base/badge/StatisticLabelBadge';
import {SectionRow} from '@modules/Core/components/layout/SectionRow';
import {logger} from '@modules/Core/util/Logger';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {TeamProfileAvatarList} from '@modules/Team/components/common/TeamProfileAvatarList';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  service: string;
  profiles: _ShortProfile[];
  xpMetric: _ProfileTrackingStatisticEntry;
}

export const TeamSoftfactsRow: React.FC<_Props> = ({xpMetric, service, profiles}) => {
  const [dictProfiles, setDictProfiles] = useState<Record<string, _ShortProfile>>({});
  useEffect(() => {
    const profilesDictById = profiles?.reduce((acc, profile) => {
      acc[profile.id] = profile;
      return acc;
    }, {});

    setDictProfiles(profilesDictById);
  }, [profiles]);

  const Icon = getServiceIcon(service);

  logger.debug('ExercisesRoundRow', {service, xpMetric});

  return (
    <SectionRow
      title={trans(PROFILE_SERVICES_TITLES[service])}
      subtitle={<TeamProfileAvatarList profiles={Object.keys(dictProfiles)} teamProfiles={dictProfiles} />}
      icon={Icon && <Icon />}
      buttonIcon={null}
      prefixContent={
        !!xpMetric?.trend && (
          <StatisticLabelBadge
            change={xpMetric?.trend}
            changeType={xpMetric?.trend > 0 ? '+' : xpMetric?.trend < 0 ? '-' : 'N'}
          />
        )
      }
    />
  );
};
