// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {_Dialog} from '@modules/Core/components/base/Dialog';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {logger} from '@modules/Core/util/Logger';
import {GainsAlertCard} from '@modules/Statistics/components/general/GainsAlertCard';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  alert: _ProfileAlert;
  onClose: (confirmed: boolean, navigateTo: string | null) => void;
  updateCurrentConfig?: (config: _Dialog) => void;
  type: _PageType;
}

/**
 * This interface is a page, basically, this popup will have multiple pages depending on data
 */
type _PageType = 'levelUp' | 'achievementLevelUp' | 'gains';

export const GamificationAlertDialog: React.FC<_Props> = ({type, alert, updateCurrentConfig}) => {
  useEffect(() => {
    if (!alert || !['levelUp', 'achievementLevelUp', 'gains'].includes(type)) {
      return;
    }
    if (type === 'gains') {
      logger.debug('Gains alert setting config');
      updateCurrentConfig?.({
        title: trans('gamification.your_development'),
        justifyTitle: 'center',
        justifyContent: 'center',
      });
    } else {
      const dataObject =
        type === 'achievementLevelUp'
          ? alert.metadata?.gainedAchievements?.config
          : alert.metadata?.levelUp?.newLevelData;
      const title =
        type === 'achievementLevelUp' ? trans('gamification.your_rewards') : trans('gamification.your_level');
      const lottieTitle =
        type === 'achievementLevelUp'
          ? trans('gamification.achievement_achieved', {
              achievement: trans(dataObject?.title),
            })
          : trans('gamification.level_achieved', {
              level: trans(dataObject?.title),
            });

      const lottieDescription = trans(dataObject?.description);
      const image = dataObject?.image;
      updateCurrentConfig?.({
        title,
        justifyTitle: 'center',
        justifyContent: 'center',
        lottieData: {
          lottieKey: image,
          style: {
            height: '300px',
            width: '300px',
          },
          description: lottieDescription,
          title: lottieTitle,
        },
      });
    }
  }, [alert, type, updateCurrentConfig]);

  if (type !== 'gains' || !alert?.metadata) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 w-full items-center justify-center h-full">
      <GainsAlertCard data={alert.metadata} />
    </div>
  );
};
