// @ts-nocheck
/* eslint-disable */

import React, {FC} from 'react';
import ReactPlayer from 'react-player';
import {twMerge} from 'tailwind-merge';
import {addCN} from '@/_core/util/cssHelper';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {getIconByName, IconCancel} from '@modules/Core/components/base/Icons/Icons';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {Typography} from '@modules/Core/components/base/Typography';
import {Action} from '@modules/Core/components/layout/Action';
import {getImage} from '@modules/Core/util/imagesUtil';
import {useLanguage} from '@modules/Translations/hooks/language';
import {trans} from '@modules/Translations/util/i18n';
import {_TutorialStep} from '@modules/Tutorials/types/tutorial.model';

export type _TutorialCardTipPositions =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | 'top'
  | 'bottom'
  | 'left-top'
  | 'right-top'
  | 'left-bottom'
  | 'right-bottom';

interface _TutorialCard {
  data: _TutorialStep;
  divRef?: React.RefObject<HTMLDivElement>;
  onClose?: () => void;
  simpleView?: boolean;
  showTitle?: boolean;
  asDialog?: boolean;
}

export const TutorialCard: FC<_TutorialCard> = ({
  asDialog = false,
  showTitle = true,
  simpleView = false,
  onClose,
  divRef,
  data,
}) => {
  const {
    icon,
    title,
    description,
    titleParams,
    descriptionParams,
    actions,
    url,
    progress,
    tip = 'left-top',
    asHtml,
    image,
  } = data;
  const {currentLanguage} = useLanguage();
  const Icon = icon && getIconByName(icon);

  const footer = (
    <div className="w-full flex items-center justify-between">
      <div className="w-[84px]">
        <ProgressBar color="success" value={progress} size="xs" />
      </div>

      <div className="flex flex-row gap-2">
        {actions.map((action, i) => (
          <Action {...action} width={i === actions?.length - 1 ? 'relative-col-width-1-5' : 'relative-col-width-1'} />
        ))}
      </div>
    </div>
  );
  const content = (
    <div className={twMerge('flex flex-col gap-3', simpleView ? 'w-full' : 'items-center')}>
      {showTitle && (
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex gap-1 items-center">
            {Icon && <Icon size="lg" />}
            <Typography bold variant={simpleView ? 'h5' : undefined}>
              {trans(title, processDescriptionParams(titleParams))}
            </Typography>
          </div>
          {Boolean(!simpleView) && <IconButton onClick={onClose} icon={IconCancel} />}
        </div>
      )}
      <div className={twMerge('w-full', asDialog ? 'text-center' : '')}>
        {asHtml ? (
          <Typography
            dangerouslySetInnerHTML={{__html: trans(description, processDescriptionParams(descriptionParams))}}
          />
        ) : (
          <Typography>{trans(description, processDescriptionParams(descriptionParams))}</Typography>
        )}
      </div>
      {url ? (
        <div className="rounded-[8px] overflow-hidden">
          <ReactPlayer
            url={url}
            height={simpleView ? undefined : '15.5rem'}
            width={simpleView ? '100%' : '27.375rem'}
            controls
            config={{
              youtube: {
                playerVars: {
                  cc_load_policy: 1, // This enables closed captions
                  hl: currentLanguage,
                  cc_lang_pref: currentLanguage,
                },
              },
            }}
          />
        </div>
      ) : null}
      {image ? (
        <div className="rounded-[8px] overflow-hidden">
          <img src={getImage(image)} alt={title} />
        </div>
      ) : null}
      {Boolean(!simpleView) && footer}
    </div>
  );

  if (simpleView) {
    return content;
  }
  return (
    <div
      ref={divRef}
      className="flex flex-col items-start shadow-2 rounded-sm border-1 border-grey300 bg-white500 max-w-[488px] relative p-3"
    >
      <Tip tip={tip} />
      {content}
    </div>
  );
};

interface _Tip {
  tip: string;
}

// not super pretty to use an SVG for this, but the alternative are hundreds of line of CSS code - especially since the design has
// two tips to have a grey border
const Tip: React.FC<_Tip> = ({tip = 'top-right'}) => (
  <div className={addCN('absolute z-[999] tutorialCardTip', tip)}>
    <img src="/tip.svg" />
  </div>
);

export function processDescriptionParams(params?: Record<string, string>): Record<string, string> {
  const result = {};

  if (!params) return result;

  // Check if the params object has a translated key and if it's true
  const {translated} = params;

  // Iterate over each key in the params object
  for (const key in params) {
    if (params.hasOwnProperty(key) && key !== 'translated') {
      // If translated is true, call trans on the param value
      // Otherwise, use the param value itself
      result[key] = translated ? trans(params[key]) : params[key];
    }
  }

  return result;
}
