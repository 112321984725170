// @ts-nocheck
/* eslint-disable */

import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {fireDialogFlow} from '@modules/FlowEngine/util/dialogFlowsUtil';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_Team} from '@modules/Team/types/team.model';
import {trans} from '@modules/Translations/util/i18n';

export function newTeamFlow(onlyInvite: boolean = false): void {
  fireDialogFlow(
    FLOWS.NewTeam,
    null,
    {
      hideButtons: true,
      showCloseButton: true,
    },
    {
      onlyInvite,
      excludeIntro: true,
      excludeTitles: true,
    }
  );
}

export function inviteToTeamFlow(teamId?: string | null): void {
  fireDialogFlow(
    FLOWS.InviteTeam,
    null,
    {
      title: trans('institution.add_institution_member'),
      hideButtons: true,
      showCloseButton: true,
    },
    {
      teamId,
    }
  );
}

export function organisationDetailsFlow(institution: _Institution): void {
  fireDialogFlow(
    'EditOrganisation',
    null,
    {
      title: trans('organisation.edit'),
      hideButtons: true,
      showCloseButton: true,
    },
    {
      institutionId: institution.id,
      name: institution.name,
    }
  );
}

export function teamDetailsFlow(team: _Team): void {
  fireDialogFlow(
    'EditTeam',
    null,
    {
      title: trans('team.edit'),
      hideButtons: true,
      showCloseButton: true,
    },
    {
      teamId: team.id,
      name: team.name,
    }
  );
}
