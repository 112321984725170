// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {TeamSoftfactsRow} from '@modules/Team/components/dashboard/TeamSoftfactsRow';
import {_TotalProgressData} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  serviceStatuses: Record<string, _ShortProfile[]>;
  relevance: string[];
  xpMetrics: Record<string, _TotalProgressData>;
}

const LIMIT = 4;
export const TeamSoftfactsOverviewItem: React.FC<_Props> = ({xpMetrics, serviceStatuses, relevance}) => {
  if (!serviceStatuses || Object.keys(serviceStatuses).length === 0) {
    return (
      <PlaceholderSection
        title={trans('team_gamification.softfacts_missing.title')}
        content={trans('team_gamification.softfacts_missing.description')}
        image={getDesignImage('your_softfacts.png')}
      />
    );
  }
  return (
    <div className="flex flex-col gap-3">
      {Object.entries(serviceStatuses ?? {})
        // lower -> first
        .sort(([a], [b]) => {
          if (!relevance.includes(a)) {
            return 1;
          }

          if (!relevance.includes(b)) {
            return -1;
          }
          return relevance.indexOf(a) - relevance.indexOf(b);
        })
        .map(([service, profiles]) => {
          return (
            <div key={service}>
              <TeamSoftfactsRow xpMetric={xpMetrics?.[service] ?? null} service={service} profiles={profiles} />
            </div>
          );
        })
        .slice(0, LIMIT)}
    </div>
  );
};
