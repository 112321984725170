// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {NoExercisesPlaceholder} from '@modules/ContentEngine/components/items/profile/dashboard/ProfileExercisesItem';
import {ProfileLockedExercisesItem} from '@modules/ContentEngine/components/items/profile/exercises/ProfileLockedExercisesItem';
import {SERVICE_INITIAL_SOFTFACTS_ANALYSIS} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {ProfileServiceExercisesCard} from '@modules/Profile/components/exercises/common/ProfileServiceExercisesCard';
import {useProfile} from '@modules/Profile/hooks/profile';
import {_ProfileExercisesRound, _ProfileServiceExercises} from '@modules/Profile/types/exercises.model';
import {isServiceDoneV2} from '@modules/Profile/util/serviceUtil';

export const ProfileExercisesOverview: React.FC<_ProfileServiceExercises> = ({exercises}) => {
  const [orderedServices, setOrderedServices] = useState<string[]>([]);
  const [initialSurveyDone, setInitialSurveyDone] = useState<boolean>(false);
  const [profile] = useProfile();

  useEffect(() => {
    if (profile) {
      setInitialSurveyDone(isServiceDoneV2(SERVICE_INITIAL_SOFTFACTS_ANALYSIS));
    }
  }, [profile]);

  useEffect(() => {
    if (exercises) {
      setOrderedServices(orderServices(exercises));
    }
  }, []);

  // Function to order services based on the latest exercise updated_at
  function findLatestUpdate(service: {rounds: _ProfileExercisesRound[]}): Date {
    let latestDate = new Date(0); // Initialize with a very old date
    service.rounds.forEach(round => {
      round.exercises.forEach(exercise => {
        const exerciseDate = new Date(exercise.updated_at);
        if (exerciseDate > latestDate) {
          latestDate = exerciseDate;
        }
      });
    });
    return latestDate;
  }

  // Function to order services based on the latest update date of their exercises
  function orderServices(
    exercises: Record<
      string,
      {
        rounds: _ProfileExercisesRound[];
        currentRound: number;
      }
    >
  ): string[] {
    // Convert the exercises object to an array of [serviceName, serviceDetails] pairs
    const servicesArray = Object.entries(exercises) as Array<
      [
        string,
        {
          rounds: _ProfileExercisesRound[];
          currentRound: number;
        },
      ]
    >;

    // Map each service to include its latest update date
    const servicesWithLatestUpdate = servicesArray.map(([serviceName, serviceDetails]) => ({
      serviceName,
      latestUpdate: findLatestUpdate(serviceDetails),
    }));

    // Sort the services by their latest update date in descending order
    servicesWithLatestUpdate.sort((a, b) => b.latestUpdate.getTime() - a.latestUpdate.getTime());

    // Extract the ordered service names
    return servicesWithLatestUpdate.map(service => service.serviceName);
  }

  logger.debug('ProfileExercisesOverview', {exercises, orderedServices, initialSurveyDone});

  if (!initialSurveyDone) {
    return <ProfileLockedExercisesItem view="card" />;
  }
  if (!Object.keys(exercises || {}).length) {
    return <NoExercisesPlaceholder />;
  }

  const content = (orderedServices || []).map(service => (
    <ProfileServiceExercisesCard service={service} profileExercises={exercises[service]} key={service} />
  ));

  if (profile?.exercisesLocked) {
    return (
      <div className="lockable-content-wrapper">
        {content}
        <div className="lockable-content-overlay">
          <span className="lockable-content-message">
            <NoExercisesPlaceholder asLock />
          </span>
        </div>
      </div>
    );
  }
  return content;
};
