// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {getStatisticImage} from '@modules/Core/util/imagesUtil';
import {GamificationAlertCard} from '@modules/Statistics/components/general/GamificationAlertCard';
import {XPGainsCard} from '@modules/Statistics/components/general/XPGainsCard';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  alert: _ProfileAlert;
  onClose: (confirmed: boolean, navigateTo: string | null) => void;
  updateCurrentConfig?: (config: any) => void;
  type: _PageType;
}

/**
 * This interface is a page, basically, this popup will have multiple pages depending on data
 */
type _PageType = 'levelUp' | 'achievementLevelUp' | 'gains';

interface _Props {
  data: _GainsMetadata;
}

interface _GainsMetadata {
  gainedExp: {
    gainedExperience: number;
    totalExperience: number;
    progression: number;
    levelProgress: {
      currentLevel: {
        index: number;
        points: number;
      };
      nextLevel: {
        index: number;
        points: number;
      };
    };
  };
  achievementProgress: Record<
    string,
    {
      config: {
        title: string;
      };
      progressTitle: string;
      progressIcon: string;
      points: number;
      progression: number;
      progress: {
        currentLevel: {
          index: number;
          points: number;
        };
        nextLevel: {
          index: number;
          points: number;
        };
      };
    }
  >;
}

export const GainsAlertCard: React.FC<_Props> = ({data}) => {
  return (
    <div className="flex flex-col w-full gap-2 pt-1">
      <div className="flex flex-row items-center w-full gap-2">
        {Boolean(data?.gainedExp.totalExperience) && (
          <XPGainsCard
            title={<Typography variant="body">{trans('gamification.total_experience')}</Typography>}
            image={getStatisticImage('experiencelogo')}
            description={<Typography bold>{data?.gainedExp?.totalExperience}</Typography>}
          />
        )}
        {Boolean(data?.gainedExp) && (
          <XPGainsCard
            title={<Typography variant="body">{trans('gamification.achievements.experienceGain.title')}</Typography>}
            image={getStatisticImage('experiencelogo')}
            description={
              <Typography bold className="text-green90">
                +{data?.gainedExp?.gainedExperience}
              </Typography>
            }
          />
        )}
      </div>

      {Boolean(data?.gainedExp?.gainedExperience) && (
        <GamificationAlertCard
          progression={data?.gainedExp?.progression}
          type="level"
          title={trans('gamification.levelProgress.display')}
          xpMetrics={data?.gainedExp?.levelProgress}
          titleIcon="xp"
        />
      )}

      {Object.entries(data?.achievementProgress ?? {}).map(([key, value]) => {
        const title = trans(value.progressTitle);

        return (
          <GamificationAlertCard
            type="achievement"
            progression={value?.progression}
            title={title}
            titleIcon={value.progressIcon}
            xpMetrics={value?.progress}
          />
        );
      })}
    </div>
  );
};
