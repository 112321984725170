// @ts-nocheck
/* eslint-disable */

import {FC, useEffect, useMemo, useRef} from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {_Dialog, Dialog} from '@modules/Core/components/base/Dialog';
import {addReactEventListener, ReactEventActionClicked, removeReactEventListener} from '@modules/Core/util/eventsUtil';

interface _Props {
  latestReleaseKey: string | null;
  updateCurrentConfig?: (config: _Dialog) => void;
  onClose?: () => void;
  asDialog?: boolean;
  open?: boolean;
}

export const LatestSoftwareUpdate: FC<_Props> = ({open, asDialog, onClose, latestReleaseKey, updateCurrentConfig}) => {
  const lastLoadedKey = useRef<string | null>(null);

  const content = useMemo(() => {
    if (!latestReleaseKey || (lastLoadedKey.current && lastLoadedKey.current === latestReleaseKey)) {
      return null;
    }

    lastLoadedKey.current = latestReleaseKey;
    return (
      <ContentEngineContainer
        type={`${latestReleaseKey}`}
        params={{
          softwareUpdateId: 'latest',
        }}
        withLoadingIndicator
      />
    );
  }, [latestReleaseKey]);

  const onReactEventActionClicked = (event: CustomEvent): void => {
    if (event?.detail?.type === 'software_update_confirm') {
      onClose?.();
    }
  };

  useEffect(() => {
    addReactEventListener(ReactEventActionClicked, onReactEventActionClicked);

    // Update the dialog configuration
    if (updateCurrentConfig) {
      updateCurrentConfig({
        showCloseButton: true,
        onClose,
        hideButtons: true,
      });
    }

    return () => {
      removeReactEventListener(ReactEventActionClicked, onReactEventActionClicked);
    };
  }, []);

  if (asDialog) {
    return (
      <Dialog open={open} {...{onClose}} showCloseButton>
        {content}
      </Dialog>
    );
  }
  return <>{content}</>;
};
