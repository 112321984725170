// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {ProfileMotivesChart} from '@modules/ContentEngine/components/items/profile/result/msa/ProfileMotivesChart';
import {Section} from '@modules/Core/components/layout/Section';
import {_ProfileMotivesGroup, _ProfileMotivesGroupResult} from '@modules/Profile/types/service.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  mainMotives: _ProfileMotivesGroupResult[];
  // All groups
  motiveGroups: Record<_ProfileMotivesGroup, _ProfileMotivesGroupResult[]>;
  profileMotives: Record<_ProfileMotivesGroup, Record<string, number>>;
}

const motiveGroupsOrder = [
  'career',
  'development',
  'selfrealization',
  'selfoptimization',
  'fun',
  'power',
  'achievement',
  'independance',
  'fairness',
  'idealism',
  'responsibility',
  'goalinternalization',
  'status',
  'security',
  'belonging',
  'acceptance',
];
export const TeamMotivesItem: React.FC<_Props> = ({profileMotives, mainMotives, motiveGroups}) => {
  const [graphData, setGraphData] = useState<number[]>([]);

  useEffect(() => {
    const data = [];
    if (motiveGroups) {
      const sortedMotiveGroups = Object.values(motiveGroups)
        .flat()
        .sort((a, b) => motiveGroupsOrder.indexOf(a.title) - motiveGroupsOrder.indexOf(b.title));
      for (const motiveGroup of sortedMotiveGroups) {
        // no decimal
        data.push(Math.round(motiveGroup.value));
      }
    }

    setGraphData(data);
  }, [motiveGroups]);

  return (
    <Section>
      <Section className="max-w-full max-h-full" title={trans('motive_structure.motive_overview')}>
        <ProfileMotivesChart width={1000} height={800} data={graphData} />
      </Section>
    </Section>
  );
};
