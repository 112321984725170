// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {logger} from '@modules/Core/util/Logger';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {ExercisesRoundRow} from '@modules/Profile/components/exercises/cards/ExercisesRoundRow';
import ProfileUpgradeDialog from '@modules/Profile/components/exercises/dialogs/ProfileUpgradeDialog';
import {useExercises} from '@modules/Profile/hooks/exercises';
import {
  _ProfileExercise,
  _ProfileExerciseCE,
  _RoundData,
  EXERCISE_STATUS_OPEN,
  EXERCISE_STATUS_STARTED,
} from '@modules/Profile/types/exercises.model';
import {createExerciseData} from '@modules/Profile/util/exercisesUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  rounds: _ProfileExerciseCE[];
  exercises: _ProfileExercise[];
  doneCount: number;
}

const LIMIT = 4;
export const ProfileExercisesItem: React.FC<_Props> = ({rounds, doneCount, exercises}) => {
  const [allExercises, setAllExercises] = useState<_ProfileExercise[]>([]);
  const allExercisesData = useExercises();
  const [data, setData] = useState<Record<string, _RoundData[]>>([]);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState<boolean>(false);
  useEffect(() => {
    // setRounds(createExerciseData(service, exercises?.[service], profileExercises)?.rounds);
    if (!rounds) {
      return;
    }

    const newRounds: Record<string, _RoundData[]> = {};
    Object.entries(rounds)
      // sort by relevance, lower -> higher
      .sort((a, b) => a[1].relevance - b[1].relevance)
      .forEach(([key, value]) => {
        const newExercisesData = createExerciseData(key, allExercisesData?.[key], value);
        newRounds[key] = newExercisesData?.rounds;
      });

    setData(newRounds);
  }, [rounds]);
  useEffect(() => {
    setAllExercises(exercises ?? []);
  }, [exercises]);

  if (!allExercises?.length) {
    return <NoExercisesPlaceholder />;
  }
  logger.debug('ProfileExercisesItem', {allExercises, rounds, data});

  // instead of allExercises, we return rounds, key = service, value = exercises,progress
  return (
    <>
      <ProfileUpgradeDialog open={showUpgradeDialog} onClose={() => setShowUpgradeDialog(false)} />

      <div className="flex flex-col gap-3">
        {Object.entries(data)
          .map(([key, value]) => {
            const currentRoundIndex = rounds?.[key]?.currentRound;
            const currentRound = value?.[currentRoundIndex];
            if (!currentRound || ![EXERCISE_STATUS_OPEN, EXERCISE_STATUS_STARTED].includes(currentRound.status)) {
              return null;
            }
            logger.debug('wtf out', {key, currentRound});

            return (
              <div key={key}>
                <ExercisesRoundRow onLockClick={() => setShowUpgradeDialog(true)} round={currentRound} service={key} />
              </div>
            );
          })
          .slice(0, LIMIT)}
      </div>
    </>
  );
};
export const NoExercisesPlaceholder: React.FC<{alreadyStartedView?: boolean; asLock?: boolean}> = ({
  alreadyStartedView,
  asLock,
}) => {
  const navigateToFlow = useNavigateToFlow();

  const title = alreadyStartedView
    ? trans('profile_modules.practice_what_you_learn_already_done_title')
    : trans('profile_modules.practice_what_you_learn_title');

  const content = alreadyStartedView
    ? trans('profile_modules.practice_what_you_learn_already_done_description')
    : trans('profile_modules.practice_what_you_learn_description');

  const buttonProps = alreadyStartedView
    ? {
        buttonLabel: trans('profile_exercises.see_all_finished'),
        onButtonClick: () => navigateToFlow(FLOWS.ProfileExercises),
      }
    : {};

  return (
    <PlaceholderSection
      alignImage={asLock ? 'start' : undefined}
      asHtml
      title={title}
      content={content}
      image="/yourexercises.png"
      {...buttonProps}
    />
  );
};
